













































































































import ClientSelectInput from '@/components/inputs/ClientSelectInput.vue';
import QuickNote from '../../bookings/components/QuickNote.vue';
import SiteSelectInput from '@/components/inputs/SiteSelectInput.vue';
import { Client, Patient, Site } from '@/models';
import { UsersViewAllLayout } from '../../shared';
import { debouncedSearch } from '@/utils/inputSearch';
import { defineComponent, ref, watch } from '@/plugins/composition';
import { redirectOnFail, useRouter } from '@/router';
import { routeNames } from '@/router/routeNames';

export default defineComponent({
  name: 'PatientIndexPage',
  components: {
    UsersViewAllLayout,
    ClientSelectInput,
    SiteSelectInput,
    QuickNote,
  },

  setup() {
    const { router } = useRouter();

    const patients = ref<Patient[]>([]);
    const total = ref(0);
    const loading = ref(false);

    const options = ref({
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      page: 1,
      itemsPerPage: 25,
      sortBy: [],
      sortDesc: [false],
    });

    const search = ref('');
    const client = ref<Client | null>(null);
    const site = ref<Site | null>(null);

    const headers = [
      { text: 'First Name', value: 'data.firstName', groupable: false },
      { text: 'Last Name', value: 'data.lastName', groupable: false },
      { text: 'Client', value: 'client', sortable: false, groupable: true },
      { text: 'Site', value: 'site', sortable: false, groupable: true },
      { text: 'Email', value: 'data.email', sortable: false, groupable: false },
      { text: 'Phone', value: 'data.phone', sortable: false, groupable: false },
      {
        text: 'Last Seen',
        value: 'last_seen',
        sortable: true,
        groupable: false,
      },
      { text: 'Actions', value: 'actions', sortable: false, groupable: false },
    ];

    const showSelect = ref(false);

    async function loadUsers(): Promise<void> {
      redirectOnFail(async () => {
        loading.value = true;

        const target = site.value ? site.value.patients() : client.value ? client.value.patients() : Patient.builder();

        let { data, meta } = await target
          .sortBy(options.value.sortBy[0], options.value.sortDesc[0])
          .where('name', search.value)
          .with('patientLocation', 'latestBooking')
          .paginate(options.value.page, options.value.itemsPerPage);

        patients.value = data;
        total.value = meta.total;
        loading.value = false;
      }, 'dashboard');
    }

    const searchUsers = debouncedSearch(() => loadUsers());

    function viewEmployee(patient: Patient): void {
      router.push({
        name: routeNames.practitioner.patients.view.profile,
        params: { uuid: patient.uuid },
      });
    }

    watch(options, () => loadUsers());
    watch(search, () => searchUsers());
    watch(client, () => loadUsers());
    watch(site, () => loadUsers());

    return {
      patients,
      total,
      loading,
      options,
      search,
      client,
      site,
      headers,
      showSelect,
      searchUsers,
      viewEmployee,
      loadUsers,
      router,
      routeNames,
    };
  },
});
