


































import BaseRouter from '@/components/BaseRouter.vue';
import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  components: { BaseRouter },
  name: 'SidebarLayout',
})
export default class extends Vue {
  drawer = false;
}
