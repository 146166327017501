






import { Booking, Patient } from '@/models';

import { PropType, defineComponent } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router';

export default defineComponent({
  name: 'QuickNote',
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'button',
    },
  },
  setup(props) {
    const { router } = useRouter();

    const createQuickNote = async () => {
      const booking = await Booking.createQuickNote(props.patient);
      router.push({ name: routeNames.practitioner.bookings.view, params: { uuid: booking.uuid } });
    };
    return {
      createQuickNote,
    };
  },
});
